import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';

const AdsenseHomeSquare1 = () => {
    const isDesktop = useMediaQuery('(min-width: 768px)');

    useEffect(() => {
        if (window.adsbygoogle && window.adsbygoogle.loaded === false) {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {}
        }
    }, [isDesktop]);

    return (
        isDesktop && (
            <div className="adsense-responsive-container">
                <div className="adsense-responsive-container__text">Anuncio</div>
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-1884623822045383"
                    data-ad-slot={4278765822}
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
            </div>
        )
    );
};

export default AdsenseHomeSquare1;
