import { useEffect } from 'react';

const AdsenseHomeHorizontal2 = () => {
    useEffect(() => {
        if (window.adsbygoogle && window.adsbygoogle.loaded === false) {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {}
        }
    }, []);

    return (
        <div className="adsense-responsive-container">
            <div className="adsense-responsive-container__text">Anuncio</div>
            <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-1884623822045383"
                data-ad-slot={2398885554}
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
        </div>
    );
};

export default AdsenseHomeHorizontal2;
