import { useRouter } from 'next/router';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Typography, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { parseISO } from 'date-fns';
import LazyLoadWrapper from '../shared/wrappers/lazy-load-wrapper';
import FixturesNewCardComponent from '../page-components/matches-card/fixture-new-card-component';
import HomeFixturesByCalendar from './home-fixtures-by-calendar';
import { Context } from '@/context';
import { Rutas } from '@/constants/rutas';
import { isMatchInProgress } from '@/helpers/fixtures-helper';

const HomeFixtures = () => {
    const { state } = useContext(Context);
    const { fixtures } = state;
    const router = useRouter();
    const [groupedFixtures, setGroupedFixtures] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [expandedDate, setExpandedDate] = useState(false);
    const [expandedLive, setExpandedLive] = useState(false);
    const [availableDates, setAvailableDates] = useState([]);

    useEffect(() => {
        if (fixtures?.rounds?.length > 0) {
            let allFixtures = [];

            fixtures.rounds.forEach((round) => {
                allFixtures = allFixtures.concat(round.fixtures);
            });

            const now = new Date();

            const grouped =
                allFixtures?.reduce((acc, fixture) => {
                    const leagueId = fixture.leagueId;
                    if (!acc[leagueId]) {
                        acc[leagueId] = { upcoming: [], past: [] };
                    }
                    if (new Date(fixture.date) >= now) {
                        acc[leagueId].upcoming.push(fixture);
                    } else {
                        acc[leagueId].past.push(fixture);
                    }
                    return acc;
                }, {}) || {};

            setGroupedFixtures(grouped);

            const dates = allFixtures.map((fixture) => fixture.date).sort();
            setAvailableDates(dates);

            const parsedDates = dates.map((date) => parseISO(date)).sort((a, b) => a - b);
            const todayDate = parsedDates.find((date) => date >= now) || parsedDates[0];
            setSelectedDate(todayDate);
        }
    }, [fixtures]);

    const handleFixture = (fixture) => {
        router.push(Rutas.PARTIDO + '/' + fixture.fixtureId + '/alineaciones-probables');
    };

    const renderLiveFixtures = () => {
        const liveFixtures = Object.keys(groupedFixtures)
            .map(Number)
            .reduce((acc, leagueId) => {
                const liveGames = groupedFixtures[leagueId].past.filter((x) =>
                    isMatchInProgress(x?.fixtureStatus)
                );
                if (liveGames.length > 0) {
                    acc[leagueId] = liveGames;
                }
                return acc;
            }, {});

        const allLiveFixtures = Object.values(liveFixtures).flat();
        const fixturesToShow = expandedLive ? allLiveFixtures : allLiveFixtures.slice(0, 3);

        if (fixturesToShow?.length > 0) {
            return (
                <div className="af-card league-fixtures" style={{ marginBottom: '24px' }}>
                    <div className="af-card__head">
                        <Typography className="af-card__head-text">Partidos en vivo</Typography>
                    </div>
                    {fixturesToShow.length === 0 ? (
                        <div
                            style={{
                                boxShadow: '0 1px 2px 1px #e1e1e1',
                                borderRadius: '4px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '16px',
                                height: '40px',
                            }}
                        >
                            <Typography variant="h6">No hay partidos en vivo</Typography>
                        </div>
                    ) : (
                        <div className="fixtures-card-container">
                            <FixturesNewCardComponent
                                fixtures={fixturesToShow}
                                handleFixture={handleFixture}
                            />
                        </div>
                    )}
                    {allLiveFixtures.length > 3 && (
                        <Button
                            onClick={() => setExpandedLive(!expandedLive)}
                            endIcon={expandedLive ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            style={{ textTransform: 'capitalize', width: '100%' }}
                        >
                            {expandedLive ? 'Ver menos' : 'Ver más'}
                        </Button>
                    )}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderFixturesByDate = () => {
        if (!fixtures?.rounds?.length) return null;

        const selectedDateFixtures = fixtures.rounds
            .flatMap((round) => round.fixtures)
            .filter((fixture) => new Date(fixture.date).toDateString() === selectedDate.toDateString());

        if (selectedDateFixtures.length === 0) {
            return (
                <div
                    style={{
                        boxShadow: '0 1px 2px 1px #e1e1e1',
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '16px',
                        height: '40px',
                    }}
                >
                    <Typography variant="h6">No hay partidos para esta fecha</Typography>
                </div>
            );
        }

        const fixturesToShow = expandedDate ? selectedDateFixtures : selectedDateFixtures.slice(0, 3);

        return (
            <div className="af-card league-fixtures" style={{ marginBottom: '16px' }}>
                <div className="af-card__head">
                    <Typography className="af-card__head-text" variant="h6">
                        Partidos del {selectedDate.toLocaleDateString()}
                    </Typography>
                </div>
                <div className="fixtures-card-container">
                    <FixturesNewCardComponent fixtures={fixturesToShow} handleFixture={handleFixture} />
                </div>
                {selectedDateFixtures.length > 3 && (
                    <Button
                        onClick={() => setExpandedDate(!expandedDate)}
                        endIcon={expandedDate ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        style={{ textTransform: 'capitalize', width: '100%' }}
                    >
                        {expandedDate ? 'Ver menos' : 'Ver más'}
                    </Button>
                )}
            </div>
        );
    };

    return (
        <LazyLoadWrapper>
            <Fragment>
                {renderLiveFixtures()}
                <HomeFixturesByCalendar
                    selectedDate={selectedDate}
                    onSelectDate={setSelectedDate}
                    availableDates={availableDates}
                />
                {renderFixturesByDate()}
            </Fragment>
        </LazyLoadWrapper>
    );
};

export default HomeFixtures;
