import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { Button, Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
import CustomImageComponent from '../shared/custom/custom-image';
import NewsIcon from '../shared/icons/news-icon';
import { Rutas } from '@/constants/rutas';

const HomeCarruselNewsComponent = ({ noticias }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const router = useRouter();

    // Función para navegar a una URL específica
    const navigate = (url) => router.push(url);

    // Renderizar las noticias en el formato esperado por ImageGallery
    const renderNoticias = () => {
        return noticias.slice(0, 8).map((article) => ({
            original: `${article.cover}?width=600&height=460`,
            thumbnail: `${article.cover}?width=135&height=95`,
            description: article.title,
            slug: article.slug,
        }));
    };

    // Manejar clic en la imagen
    const handleImageClick = () => {
        const selectedArticle = noticias[currentIndex];
        let url = '';

        if (selectedArticle && selectedArticle.url?.length > 1) {
            url = selectedArticle.url;
        } else if (selectedArticle && selectedArticle.slug) {
            url = `${Rutas.NOTICIAS}/${selectedArticle.slug}`;
        }

        router.push(url);
    };

    // Renderizar cada item del carrusel con su imagen personalizada
    const renderItem = (item) => {
        return (
            <div className="image-gallery-image" style={{ position: 'relative' }}>
                <CustomImageComponent
                    src={item.original}
                    alt="Imagen de noticia"
                    onClick={handleImageClick}
                    width={400}
                    height={230}
                    sizes="100vw"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        cursor: 'pointer',
                    }}
                    title="Imagen de la noticia"
                />
                {item.description && <span className="image-gallery-description">{item.description}</span>}
            </div>
        );
    };

    return (
        <div style={{ width: '100%', margin: 'auto', marginBottom: '16px' }} className="carousel-noticias">
            <h2 style={{ fontSize: '20px', fontWeight: '700' }}>Últimas noticias de fantasy fútbol</h2>
            {noticias?.length > 0 ? (
                <>
                    <ImageGallery
                        items={renderNoticias()}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        autoPlay={true}
                        slideDuration={1000}
                        slideInterval={10000}
                        onClick={handleImageClick}
                        onSlide={(index) => setCurrentIndex(index)} // Actualizar el índice del artículo actual
                        renderItem={renderItem} // Renderizar cada imagen
                    />
                    <Button
                        style={{ width: '100%', marginBottom: '12px' }}
                        variant="outlined"
                        onClick={() => navigate(Rutas.NOTICIAS)}
                    >
                        <NewsIcon />
                        <span style={{ marginLeft: '4px' }}>Ver todas las noticias</span>
                    </Button>
                </>
            ) : (
                <Skeleton variant="rectangular" width="100%" height={350} className="mb-25" />
            )}
        </div>
    );
};

export default HomeCarruselNewsComponent;
