import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { Button, useMediaQuery } from '@mui/material';
import NewsIcon from '../shared/icons/news-icon';
import NoticiasListComponent from '../noticias/noticia-list-component';
import AdsenseHomeSquare1 from '../ads-components/adsense/home/adsense-home-square-1';
import AdsenseHomeHorizontal2 from '../ads-components/adsense/home/adsense-home-horizontal-2';
import LazyLoadWrapper from '../shared/wrappers/lazy-load-wrapper';
import AdsenseHomeHorizontal1 from '../ads-components/adsense/home/adsense-home-horizontal-1';
import HomeActivities from './home-activities';
import HomeFixtures from './home-fixtures';
import HomeCarruselNewsComponent from './home-carrusel-news';
import HomeFlashList from '@/components/home/home-flash-list';
import { Context } from '@/context';
import { REDUCERS } from '@/constants/reducers';
import { Rutas } from '@/constants/rutas';

const HomePageComponent = ({ actividades, noticiasFlash, noticias }) => {
    const { state, dispatch } = useContext(Context);
    const router = useRouter();
    const { activities } = state;
    const isDesktop = useMediaQuery('(min-width:960px)');

    useEffect(() => {
        if (actividades?.length > 0) {
            dispatch({
                type: REDUCERS.UPDATE_ACTIVITIES,
                payload: {
                    ...activities,
                    activities: [...activities.activities, ...actividades],
                },
            });
        }
    }, [actividades, noticiasFlash, noticias]);

    return (
        <div className="">
            <div className="row home-container__main-row mb-25">
                <div className="col-12">
                    <h1>Noticias, actividades y noticias flash</h1>
                </div>
                <div className="col-12 col-lg-8">
                    <NoticiasListComponent
                        numberOfArticles={1}
                        start={0}
                        noticias={noticias}
                        col={12}
                        postHeight={isDesktop ? 440 : null}
                    />

                    <AdsenseHomeHorizontal1 />

                    <NoticiasListComponent numberOfArticles={6} start={1} noticias={noticias} />

                    <AdsenseHomeHorizontal2 />

                    <LazyLoadWrapper>
                        <HomeFixtures />
                    </LazyLoadWrapper>
                </div>
                <div className="col-12 col-lg-4" id="noticias-flash-container">
                    <LazyLoadWrapper>
                        <HomeActivities
                            customActivities={actividades}
                            isPage={false}
                            showHeader={true}
                            showFilters={false}
                            col={6}
                            customTake={5}
                        />
                    </LazyLoadWrapper>

                    <AdsenseHomeSquare1 />

                    {isDesktop && (
                        <LazyLoadWrapper>
                            <HomeCarruselNewsComponent noticias={noticias} />
                        </LazyLoadWrapper>
                    )}
                    <LazyLoadWrapper>
                        <HomeFlashList
                            take={5}
                            header="Noticias flash de fantasy fútbol"
                            hideHeader={false}
                            hideSearch={true}
                            noticiasFlash={noticiasFlash}
                        />
                    </LazyLoadWrapper>

                    <AdsenseHomeSquare1 />
                </div>

                <LazyLoadWrapper>
                    <NoticiasListComponent numberOfArticles={-1} start={7} noticias={noticias} col={4} />
                </LazyLoadWrapper>

                <LazyLoadWrapper>
                    <Button
                        color="primary"
                        aria-haspopup="true"
                        variant="outlined"
                        disableElevation
                        onClick={() => router.push(Rutas.NOTICIAS)} // Función para cargar más artículos
                        style={{ width: '100%' }}
                    >
                        <NewsIcon />
                        <span style={{ marginLeft: '4px' }}>Ver todas las noticias</span>
                    </Button>
                </LazyLoadWrapper>
            </div>
        </div>
    );
};

export default HomePageComponent;
